* {
  margin: 0;
  padding: 0;
}

a {
  color: black;
  text-decoration: none;
}

li {
  list-style: none;
}

input {
  position: relative;
  height: 100%;
}

input[type="email"] {
  padding: 7px 8px;
  border: 1px solid rgb(206, 206, 206);
}
input[type="button"] {
  border: none;
  padding: 7px 12px;
  background: rgb(79, 223, 79);
  color: rgb(255, 255, 255);
  border: 1px solid rgb(79, 223, 79);
  cursor: pointer;
}

main {
  flex: 1;
}

footer {
  margin-top: 100px;
  bottom: 0;
  /* background: rgb(46, 46, 46); */
  color: white;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footerDesc {
  color: black;
  max-width: 320px;
  font-size: 14px;
  margin-top: 30px;
  line-height: 2;
  margin-bottom: 30px;
  font-weight: 600;
}
/* Bottom - copyright and legal stuff */

footer .info {
  width: 87%;
  color: #767676;
  padding: 1rem 0rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #dbdbdb;
  margin-top: 50px;
  padding-top: 25px;
  padding-bottom: 25px;
}

footer .info .legal {
  color: #767676;
  display: flex;
  flex-wrap: nowrap;
}

footer .info .legal > a {
  color: #767676;
}

/* Middle - social media links */

/* footer .social {
  padding: 1.5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

footer .social i {
  font-size: 1.4rem;
  margin: 2px 8px;
  cursor: pointer;
}

footer .social:before {
  position: absolute;
  content: "";
  width: 90%;
  height: 1px;
  background: rgba(255, 255, 255, 0.192);
  top: 0;
  left: 50%;
  transform: translateX(-50%);
} */
.socialMedia {
  min-width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Top - pages, email newsletter form ... */

footer .top {
  padding: 2rem 0;
  display: flex;
  align-items: baseline;
  flex-direction: row;
  justify-content: space-between;
  width: 87%;
}

footer .top .pages {
  display: flex;
  flex-direction: row;
  gap: 50px;
}

footer .top .pages ul {
  margin: 20px;
}

footer .top .pages ul li {
  margin-top: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  gap: 15px;
}

.sectionHeading {
  color: black;
  font-weight: 600;
  font-size: 18px;
}
.sectionItemLeft img {
  margin-right: 20px;
  margin-top: 25px;
}

.sectionItems {
  display: flex;
  justify-content: flex-start;
  gap: 100px;
}
/* Responsive adjustments */
@media screen and (max-width: 768px) {
  footer .top {
    flex-direction: column;
  }

  footer .top .pages ul {
    margin: 10px;
  }
}

@media screen and (max-width: 600px) {
  footer .top .pages {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  footer .info {
    flex-direction: column;
  }

  footer .info > .copyright {
    margin-top: 10px;
    text-align: center;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 380px) {
  footer .top .pages {
    justify-content: center;
    flex-direction: column;
  }

  footer .info .legal {
    justify-content: center;
  }

  footer .info .legal a {
    margin: 0;
    text-align: center;
  }
}
