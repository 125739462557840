@import "~utils/vars";

body,
html {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
  font-family: "Manrope", sans-serif;
}

img {
  user-select: none !important;
  --webkit-user-drag: none;
}

a {
  text-decoration: none;
  color: #555;
}

button {
  border: none;
  cursor: pointer;
}
h2 {
  color: #616161;
  font-weight: 500;
  font-size: 1.5rem;
}

.withPadding {
  padding-inline: $inlinePadding;
}
